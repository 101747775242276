import Header from "../components/header/header";
import { Outlet } from 'react-router-dom'
import Footer from "../components/Footer/Footer";
import { useState } from "react";



const Layout = () => {
    const [showForm, setShowForm] = useState(false);


    return (
        <>
            <Header showForm={showForm} setShowForm={setShowForm} />
            <Outlet context={{ showForm, setShowForm }} />
            <Footer />
        </>
    );
}

export default Layout