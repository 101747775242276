import { useRef, useState } from "react";
import Buttons, { ButtonStyles } from "../buttons/Buttons";
import styles from "./HeroSection.module.scss";
import { HiArrowNarrowRight as RightArrowIcon } from 'react-icons/hi'
import SignUpForm from "./SignUpForm";
import CalendlyPopup from "../calendlyPopup/CalendlyPopup";
import { PrefilledCalendly } from "../header/header";

const HeroSection = ({ showForm, setShowForm }) => {
  // const [showForm, setShowForm] = useState(false);
  const [prefillAnswer, setPrefillAnswer] = useState<PrefilledCalendly>({
    name: "",
    email: "",
  });

  const [showCalendly, setShowCalendly] = useState(false);

  const formRef = useRef(null);

  const handleFormReset = () => {
    console.log('formRef', formRef)
    if (formRef.current) {
      formRef.current.resetForm(); // This will reset the form
    }
  };

  const handleSave = (values) => {
    // console.log(values);
    setPrefillAnswer({
      name: `${values["firstName"]} ${values["lastName"]}`,
      email: `${values["emailId"]}`,
    });
    handleFormReset();
    setShowCalendly(true);
  };


  return (
    <section id="hero-section" className={`${styles.heroSection} ${showForm ? styles.showForm : ''}`}>
      <div className={`${styles.leftContainer} `}>
        <div className={styles.textContainer}>
          <h1 className={styles.title}>
            Dashboards That Help Your Brand <br /> Smash Its Sales Targets
            {/* Dashboards that help your brand<br />smash its sales targets. */}
          </h1>
          <div className={styles.subtitle}>The Data Platform Built for Fast Growing Online Brands</div>
        </div>
        <div className={`${styles.btnRootContainer} ${showForm ? styles.hidden : ''}`}>
          <div>
            <Buttons type={ButtonStyles.SECONDARY} onClick={() => {
              setShowForm(!showForm)
            }}>
              <div className={styles.btnContainer}>
                <div className={styles.btnText}>
                  Go Live in Just 10 minutes!
                </div>
                <div className={styles.iconContainer}>
                  <RightArrowIcon />
                </div>
              </div>
            </Buttons>
          </div>
          <div className={styles.btnFooterText}>
            And It's Free!
          </div>
        </div>
      </div>
      <div className={`${styles.rightContainer} ${showForm ? styles.showForm : ''}`}>
        <SignUpForm toggleForm={() => {
          setShowForm(!showForm);
        }} handleSave={handleSave} ref={formRef} />
      </div>

      <CalendlyPopup
        showPopup={showCalendly}
        setShowPopup={setShowCalendly}
        prefillValues={prefillAnswer}
      />
    </section>
  );
};

export default HeroSection;
