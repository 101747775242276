import styles from './BrandsSection.module.scss';
import zlade from '../../assets/images/brands/logo_zlade.webp';
import assembly from '../../assets/images/brands/logo_assembly.webp';
import bluetea from '../../assets/images/brands/logo_bluetea.webp';
import bummer from '../../assets/images/brands/logo_bummer.webp';
import friendsdiaper from '../../assets/images/brands/logo_friendsdiaper.webp';
import godesi from '../../assets/images/brands/logo_godesi.webp';
import goodbug from '../../assets/images/brands/logo_goodbug.webp';
import peesafe from '../../assets/images/brands/logo_peesafe.webp';
import saadaa from '../../assets/images/brands/logo_saadaa.webp';
import whatsupwellness from '../../assets/images/brands/logo_whatsupwellness.webp';
import winston from '../../assets/images/brands/logo_winston.webp';
import zavya from '../../assets/images/brands/logo_zavya.webp';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useRef } from 'react';
import { Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Swiper as SwiperType } from "swiper";


const allBrands = [
    { "brandName": "zlade", "img": zlade },
    { "brandName": "assembly", "img": assembly },
    { "brandName": "bluetea", "img": bluetea },
    { "brandName": "bummer", "img": bummer },
    { "brandName": "friendsdiaper", "img": friendsdiaper },
    { "brandName": "godesi", "img": godesi },
    { "brandName": "goodbug", "img": goodbug },
    { "brandName": "peesafe", "img": peesafe },
    { "brandName": "saadaa", "img": saadaa },
    { "brandName": "whatsupwellness", "img": whatsupwellness },
    { "brandName": "winston", "img": winston },
    { "brandName": "zavya", "img": zavya }
];

const BrandsSection = () => {
    const swiperRef = useRef<SwiperType>();


    return (
        <section className={styles.brandsSection}>
            <div className={styles.topContainer}>
                <div className={styles.title}>
                    Trusted by 250+ D2C Brands
                </div>
            </div>
            <div className={styles.brandCarouselContainer}>
                <Swiper
                    modules={[Mousewheel, Autoplay]}
                    loop={true}
                    breakpoints={{
                        850: {
                            slidesPerView: 6,
                            spaceBetween: 24
                        }
                    }}
                    centeredSlides={true}
                    spaceBetween={24}
                    slidesPerView={1.5}
                    direction="horizontal"
                    loopFillGroupWithBlank={true}
                    autoplay={{
                        delay: 1000,
                        // disableOnInteraction: true,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                    }}
                    mousewheel={{
                        forceToAxis: true,
                    }}
                >
                    {allBrands.map((item) => {
                        return (
                            <SwiperSlide
                                // className={styles.customSwiperSlide}
                                key={item.brandName}>
                                <div className={styles.brandCardRootContainer}>
                                    <div className={styles.imgContainer}>
                                        <img src={item.img} alt={item.brandName} />
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
        </section>
    );
}

export default BrandsSection