import { Field, Form } from 'react-final-form';
import styles from './SignUpForm.module.scss';
import { RiCloseLine as CloseIcon } from 'react-icons/ri'
import { customEmailValidation, domainNameValidation, reqiredValidation } from '../Footer/components/validation/validationFunctions';
import { MenuItem, Select } from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomTextField from '../Footer/components/TextField/CustomTextfield';
import Buttons, { ButtonStyles } from '../buttons/Buttons';
import { ScheduleDemoAnswers } from '../EarlyAccess/EarlyAccess';
import { forwardRef, useImperativeHandle } from 'react';
import axios from 'axios'

export enum btnSelectType {
    bookDemo = "BOOK A DEMO",
    createAccount = "CREATE ACCOUNT"
}

type Props = {
    toggleForm: () => void;
    handleSave: (values: any) => void;
}

// Styled Select component
const StyledSelect = styled(Select)(({ theme }) => ({
    fontSize: "1.875rem",
    color: "#212121",
    fontFamily: "Quicksand",
    fontWeight: 600,

    [theme.breakpoints.down(760)]: {
        fontSize: "1.5rem",
    },

    '& .MuiInputBase-root': {
        fontSize: "1.875rem",
        color: "#212121",
        fontFamily: "Quicksand",
        fontWeight: 600,


    },
}));

// Styled MenuItem component
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    fontSize: "1.875rem",
    color: "#212121",
    fontFamily: "Quicksand",
    fontWeight: 600,
    [theme.breakpoints.down(760)]: {
        fontSize: "1.5rem",
    }
}));

const SignUpForm = forwardRef(({ toggleForm, handleSave }: Props, ref) => {

    let formValues = {};

    useImperativeHandle(ref, () => ({
        resetForm() {
            form.reset();

            // Manually reset the touched state for each field
            setTimeout(() => {
                ['firstName', 'lastName', 'emailId', 'companyWebsite', 'customerType'].forEach((field) => {
                    form.resetFieldState(field);
                });
            }, 0)
        },
    }));

    let form = null;

    const handleFormReset = () => {
        form.reset();

        // Manually reset the touched state for each field
        setTimeout(() => {
            ['firstName', 'lastName', 'emailId', 'companyWebsite', 'customerType'].forEach((field) => {
                form.resetFieldState(field);
            });
        }, 0)
    }

    const handleFormSubmit = async (values) => {
        console.log('values', values);
        let submittedValues: ScheduleDemoAnswers = {
            customerType: values["customerType"],
            name: `${values["firstName"]} ${values["lastName"]}`,
            emailId: values["emailId"],
            phoneNumber: values["phoneNumber"],
            companyWebsite: values["companyWebsite"],
            referredFrom: values["referredFrom"],
            clickedClose: false,
            action: values?.btnSelect
        };
        try {
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}/api/clickmerito/leads`,
                    submittedValues
                )
                .then((res) => res.data);
        } catch (error) {
            console.log('error', error);
        }
        if (values?.btnSelect == btnSelectType.bookDemo) {
            handleSave(values);
        } else {
            const link = `${process.env.REACT_APP_PLATFORM_URL}/register?firstName=${values['firstName']}&lastName=${values['lastName']}&email=${values['emailId']}&companyWebsite=${values['companyWebsite']}`
            window.location.href = link;
            handleFormReset();
        }
        return {}
    }

    const clearValidationErrors = () => {
        // List of field names for which you want to clear errors
        const fields = ['firstName', 'lastName', 'emailId', 'companyWebsite', 'customerType'];
        
        // Iterate over each field and reset its validation state
        fields.forEach((field) => {
            form.resetFieldState(field);
        });
    };

    const runOnClose = async () => {
        clearValidationErrors()
        if (
            formValues["firstName"] &&
            formValues["emailId"] &&
            !customEmailValidation(formValues["emailId"])
        ) {
            console.log("form values", formValues);
            let submittedValues: ScheduleDemoAnswers = {
                customerType: formValues["customerType"],
                name: `${formValues["firstName"]} ${formValues["lastName"] ?? ""}`,
                emailId: formValues["emailId"],
                phoneNumber: formValues["phoneNumber"],
                companyWebsite: formValues["companyWebsite"],
                referredFrom: formValues["referredFrom"],
                clickedClose: true,
                action: null,
            };
            try {
                const data = await axios
                    .post(
                        `${process.env.REACT_APP_API_URL}/api/clickmerito/leads`,
                        submittedValues
                    )
                    .then((res) => res.data);
                // setLoading(false);
                // handleSave(values);
                // clearSearchParams();
            } catch (error) {
                // setLoading(false);
                // clearSearchParams();
                console.log(error);
            }
        }
    };

    return (
        <div className={styles.formRootContainer}>
            <div className={styles.headerContainer}>
                <div className={styles.title}>
                    Enter Your Details
                </div>
                <div className={styles.iconContainer} onClick={() => {
                    toggleForm();
                    runOnClose();
                }}>
                    <CloseIcon />
                </div>
            </div>
            <Form
                onSubmit={(values) => handleFormSubmit(values)}
                // initialValues={{
                //     customerType: "Brand"
                // }}
                initialValues={{
                    customerType: "Brand", // Define initial values
                }}
                render={({ handleSubmit, pristine, hasValidationErrors, submitError, values, form: formApi }) => {
                    // console.log('form values', values)
                    form = formApi
                    formValues = values
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className={styles.formSection}>
                                <Field
                                    name="customerType"
                                    validate={(values) =>
                                        reqiredValidation(values, "")
                                    }
                                >
                                    {(props) => {
                                        return (
                                            <div className={styles.selectFieldRootContainer}>
                                                <div className={styles.label}>
                                                    We Are a
                                                </div>
                                                <div className={styles.selectFieldContainer}>
                                                    <StyledSelect
                                                        {...props.input}
                                                        fullWidth={true}
                                                        value={props.input.value}
                                                        variant={"standard"}
                                                        onChange={props.input.onChange}
                                                        error={props.meta.error && props.meta.touched}

                                                    >
                                                        <StyledMenuItem
                                                            // style={{ fontSize: "1.875rem", color: "#212121", fontFamily: "Quicksand", fontWeight: 600 }} 
                                                            value={"Brand"}
                                                        >Brand
                                                        </StyledMenuItem>
                                                        <StyledMenuItem
                                                            // style={{ fontSize: "1.875rem", color: "#212121", fontFamily: "Quicksand", fontWeight: 600 }} 
                                                            value={"Agency"}
                                                        >Agency</StyledMenuItem>
                                                    </StyledSelect>
                                                    {props.meta.touched && props.meta.error ?
                                                        <div className={styles.errorText}>
                                                            {props.meta.error}
                                                        </div> : null}
                                                </div>
                                            </div>
                                        );
                                    }}
                                </Field>
                                <div className={styles.flexField}>
                                    <Field
                                        name="firstName"
                                        validate={(values) =>
                                            reqiredValidation(values, "First Name")
                                        }
                                    >
                                        {(props) => {
                                            console.log('meta props', props.meta)
                                            return (
                                                <CustomTextField
                                                    {...props.input}
                                                    maxLength={25}
                                                    size='lg'
                                                    label={"First Name"}
                                                    value={props.input.value}
                                                    onChange={props.input.onChange}
                                                    // placeholder={"Name"}
                                                    error={
                                                        props.meta.error && props.meta.touched
                                                            ? props.meta.error
                                                            : props.meta.submitError
                                                                ? props.meta.submitError
                                                                : null
                                                    }
                                                />
                                            );
                                        }}
                                    </Field>

                                    <Field
                                        name="lastName"
                                        validate={(values) =>
                                            reqiredValidation(values, "Last Name")
                                        }
                                    >
                                        {(props) => {
                                            return (
                                                <CustomTextField
                                                    {...props.input}
                                                    maxLength={25}
                                                    size='lg'
                                                    label={"Last Name"}
                                                    value={props.input.value}
                                                    onChange={props.input.onChange}
                                                    // placeholder={"Name"}
                                                    error={
                                                        props.meta.error && props.meta.touched
                                                            ? props.meta.error
                                                            : props.meta.submitError
                                                                ? props.meta.submitError
                                                                : null
                                                    }
                                                />
                                            );
                                        }}
                                    </Field>



                                </div>

                                <Field name="emailId" validate={customEmailValidation}>
                                    {(props) => {
                                        return (
                                            <CustomTextField
                                                {...props.input}
                                                maxLength={50}
                                                size='lg'
                                                label={"Email ID"}
                                                value={props.input.value}
                                                onChange={props.input.onChange}
                                                // placeholder={"Email Id"}
                                                error={
                                                    props.meta.error && props.meta.touched
                                                        ? props.meta.error
                                                        : props.meta.submitError
                                                            ? props.meta.submitError
                                                            : null
                                                }
                                            />
                                        );
                                    }}
                                </Field>

                                <Field
                                    name="companyWebsite"
                                    validate={(values) =>
                                        domainNameValidation(values, "Company Website")
                                    }
                                >
                                    {(props) => {
                                        return (
                                            <CustomTextField
                                                {...props.input}
                                                maxLength={250}
                                                size='lg'
                                                label={"Company Website"}
                                                value={props.input.value}
                                                onChange={props.input.onChange}
                                                // placeholder={"Name"}
                                                error={
                                                    props.meta.error && props.meta.touched
                                                        ? props.meta.error
                                                        : props.meta.submitError
                                                            ? props.meta.submitError
                                                            : null
                                                }
                                            />
                                        );
                                    }}
                                </Field>

                                <div className={`${styles.flexField} ${styles.alignBottom}`}>
                                    <div className={styles.btnRootContainer}>
                                        <Buttons type={values?.customerType == "Agency" ? ButtonStyles.PRIMARY : ButtonStyles.OUTLINE} onClick={() => {

                                            formApi.change('btnSelect', btnSelectType.bookDemo)

                                            handleSubmit();
                                        }}>
                                            <div className={styles.btnText}>
                                                Book A Demo
                                            </div>
                                        </Buttons>
                                    </div>
                                    {values?.customerType != "Agency" ? <div className={styles.btnRootContainer}>

                                        <Buttons type={ButtonStyles.PRIMARY} onClick={() => {
                                            formApi.change('btnSelect', btnSelectType.createAccount)
                                            handleSubmit();
                                        }}>
                                            <div className={styles.btnText}>
                                                Create Account
                                            </div>
                                        </Buttons>
                                    </div> : null}
                                </div>
                            </div>

                        </form>
                    )
                }}
            />
        </div>
    );
});

export default SignUpForm;